
import 'swiper/css/swiper.min.css'
import Swiper from 'swiper'
import Card from '~/components/slices/Card.vue'
export default {
  name: 'CardGallery',
  props: ['slice'],
  components: { Card },
  data() {
    return {
      swiper: null
    }
  },
  computed: {
    hasLink(){
      return this.slice.card_cta_link && (this.slice.card_cta_link.url || this.slice.card_cta_link.uid)
    }
  },
  mounted() {
    const self = this
    this.swiper = new Swiper(`#${this.slice.id}-card-gallery`, {
      slidesPerView: 1.25,
      spaceBetween: 0,
      direction: 'horizontal',
      loop: true,
      watchOverflow: true,
      breakpoints: {
        1280: {
          slidesPerView: 1,
          spaceBetween: 10
        }
      },
      pagination: {
        el: `#${this.slice.id}-card-gallery-pagination`,
        clickable: true,
        renderBullet(index, className) {
          return `<div class="swiper-pagination-bullet" tabindex="${index}" role="button" aria-label="Go to slide ${index}">
            ${self.slice.items[index].card_gallery_title}
            <div class="swiper-pagination-bullet-content fadeIn my-2">${self.$prismic.asHtml(self.slice.items[index].card_gallery_content)}</div>
          </div>`
        },
      },
    })
  }
}
